<template>
    <div class="container" id="FeatureMainContainer">
        <div class="d-block d-md-none" id="margining-top-xs"></div>
        <div class="d-none d-md-block" id="margining-top"></div>
        <div class="row" id="FeatureMainTitleContainer">
            <div class="col-1 d-none d-md-block d-lg-none" id="FeatureMainSubtitle">{{ Card_subtitle }}</div>
            <div class="col-1 d-none d-lg-block d-xl-none" id="FeatureMainSubtitle_lg">{{ Card_subtitle }}</div>
            <div class="col-1 d-none d-xl-block" id="FeatureMainSubtitle_xl">{{ Card_subtitle }}</div>
            <div class="d-md-block d-none col-xl-7 col-lg-8 offset-md-0 offset-lg-0 offset-xl-0" id="FeatureMainTitle">젠스웰은 운전의 즐거움을 주기위한<br>다양한 사운드 솔루션 입니다.</div>
            <div class="d-md-none d-block col-12 offset-0" id="FeatureMainTitle_xs">젠스웰은 운전의 즐거움을 주기위한 다양한 사운드 솔루션 입니다.</div>
        </div>
        
        <div class="row" id="FeatureMainDescriptionContainer">            
            <img :src="require('@/assets/'+ ImageSrc)" class="img-fluid col-12 " id="FeatureMainDescriptionImg"/>
            <div class="w-100"></div>
            <div class="col-xl-6 col-lg-7 col-md-8 col-sm-11 col-xs-12" id="FeatureMainDescription">
              
              <p class="d-none d-lg-block" id="nowrapping">엔진음을 풍부하게 해주고, 시트에 장착하여 풍부한 우퍼효과와 진동을 전달하여</br>운전에 재미를 주고, 다이나믹한 배기음으로 고성능 차량의 아이덴티티를 살리는 기술과</br>제품으로 새로운 시장을 개척하고자 합니다.</p>
              <p class="d-block d-lg-none">{{ Card_description }}</p>
            </div>
            
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FeatureMain',
  components: {
      
  },
  props:['Card_subtitle', 'Card_title', 'Card_description', 'ImageSrc']
  ,
  data : ()=>
  {
    return {}
    // if(true)                // depending on card selection
    //     return {
    //         Card_title: "젠스웰은 운전의 즐거움을 주기위한 다양한 사운드 솔루션 입니다.",
    //         Card_subtitle: "Sound Control Solution",
    //         Card_description: "엔진음을 풍부하게 해주고, 시트에 장착하여 풍부한 우퍼효과와 진동을 전달하여 운전에 재미를 주고, 다이나믹한 배기음으로 고성능 차량의 아이덴티티를 살리는 기술과 제품으로 새로운 시장을 개척하고자 합니다.",
            
    //     }
    // else
    //     return {
    //         Card_title: "가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다.",
    //         Card_subtitle: "Fleet Management Solution",
    //         Card_description: "LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합 관리하는 솔루션으로 발전해 나아갈 것 입니다.",
            
        // }
  }
}
</script>


<style scoped>

#FeatureMainDescriptionImg{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: relative;
    bottom:24px;
}
#FeatureMainDescription{
  word-break: keep-all;
  text-align: left;
  font-family: 'NanumSquare';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
}
#FeatureMainDescriptionContainer{
    margin-top: 10%;
    position: relative;
    /* margin-bottom: 10vh; */
}
#FeatureMainContainer{
    
    height:100%;
    min-width: 320px;
    /* margin-bottom: 20vh; */

}
#FeatureMainSubtitle{

  /* font-weight:800; */
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  
  
  transform: translateX(-20%) translateY(710%) rotate(-90deg);
  /* top: 108px;
  left: -28px; */
  width: auto;
  height: auto;
  white-space: nowrap;
  position: absolute;
  padding: 0;
}
#FeatureMainSubtitle_lg{

  /* font-weight:800; */
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  
  
  transform: translateX(-20%) translateY(660%) rotate(-90deg);
  /* top: 108px;
  left: -28px; */
  width: auto;
  height: auto;
  white-space: nowrap;
  position: absolute;
  padding: 0;
}
#FeatureMainSubtitle_xl{

  
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  
  
  transform: translateX(-20%) translateY(600%) rotate(-90deg);
  
  width: auto;
  height: auto;
  white-space: nowrap;
  position: absolute;
  padding: 0;
}

#FeatureMainTitle_xs{
  /* margin-bottom: 3vh; */
  word-break: keep-all;
  text-align: left;
  font-family: 'NanumSquare';
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #151819;
  position: relative;
}

#FeatureMainTitle{
  left: 36px;
  text-align: left;
  font-family: 'NanumSquare';
  font-size: 41px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #151819;
  position: relative;
}

#FeatureMainTitleContainer{
    min-height: 120px;
    position: relative;
}

#nowrapping{
  white-space: nowrap;
  height:auto;
}
#margining-top{
  min-height: 128px;
}
#margining-top-xs{
  min-height: 86px;
}
</style>