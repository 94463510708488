<template>
    <div class="container" style="min-width:320px;">
        <div class="d-block d-md-none" id="margining-top-xs"></div>
        <div class="d-none d-md-block" id="margining-top"></div>

        <div class="row" id="ContactTouchContainer">
            <div class="col-1 d-none d-xl-block" id="ContactSubtitle_xl">{{ Card_subtitle }}</div>
            <div class="col-1 d-none d-lg-block d-xl-none" id="ContactSubtitle">{{ Card_subtitle }}</div>
            <div class="col-1 d-none d-md-block d-lg-none" id="ContactSubtitle_md">{{ Card_subtitle }}</div>
            <div class="d-none d-md-block col-xl-8 col-lg-9 " id="ContactTitle">{{ Card_title }}</div>
            <div class="d-block d-md-none col-12 offset-0" id="ContactTitle_xs">{{ Card_title }}</div>
            <div class="w-100 d-block d-md-none" id="ContactMarginer_xs"></div>
            <div class="w-100 d-none d-md-block" id="ContactMarginer_sm"></div>
        </div>
        <ContactTouchForm/>

    </div>
</template>

<script>
// @ is an alias to /src
import ContactTouchForm from '@/components/contact/ContactTouchForm.vue'

export default {
  name: 'ContactTouch',
  components: {
      ContactTouchForm,
  },
  data : ()=>
  {
    if(true)                // depending on card selection
        return {
            Card_title: "고객님의 차량관리 및 즐거운 운전경험 제공을 위해 최선을 다하겠습니다.",
            Card_subtitle: "Get In Touch",
        }
    else
        return {
            Card_title: "가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다.",
            Card_subtitle: "Fleet Management Solution",
            Card_description: "LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합 관리하는 솔루션으로 발전해 나아갈 것 입니다.",
        }
  }
}
</script>


<style scoped>
#ContactMarginer_xs{
    height: 84px;
}
#ContactMarginer_sm{
    height: 64px;
}
#FeatureMainDescription{
  text-align: left;
  font-family: 'NanumSquare';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
}
#FeatureMainDescriptionContainer{
    margin-top: 10%;
    position: relative;
}
#FeatureMainContainer{
    margin-top: 20%;
    height: 1000px;
}
#ContactSubtitle{

  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  

  transform: translateX(-20%) translateY(320%) rotate(-90deg);
  white-space: nowrap;
  position: absolute;
}
#ContactSubtitle_md{
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  

  transform: translateX(-15%) translateY(380%) rotate(-90deg);
  white-space: nowrap;
  position: absolute;
}

#ContactSubtitle_xl{
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  color: #1D55E8;  

  transform: translateX(-20%) translateY(280%) rotate(-90deg);
  white-space: nowrap;
  position: absolute;
}

#ContactTitle{
  left: 36px;
  text-align: left;
  height: 96px;
  word-break: keep-all;
  font-family: 'NanumSquare';
  font-size: 41px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  
  color: #151819;
  position: relative;
}
#ContactTitle_xs{
  text-align: left;
  height: 96px;
  font-family: 'NanumSquare';
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  word-break: keep-all;
  color: #151819;
  position: relative;
}

#ContactTouchContainer{
    position: relative;
    min-width: 320px;
}

#margining-top{
  min-height: 128px;
}
#margining-top-xs{
  min-height: 86px;
}
</style>