<template>
  <div class="container">
    <div class="d-none">
      <img src="@/assets/orangeRadio.svg"/>
      <img src="@/assets/blueRadio.svg"/>
    </div>

    <div class="row d-block d-md-block" id="agreementContainer">
      <div class="col-12" id="agreementTitle">
        개인정보 수집 및 이용에 대한 안내 (필수)
      </div>

      <div class="col-12" id="agreementContent">
        <p>
          고객님들의 의견 접수에 필요한 최소한의 개인정보를 수집하고 있으며, 본 문의 기능은 개인정보 이용 동의 후 이용하실 수 있습니다.<br><br>
          1. 필수 수집 항목: 이름, 휴대폰 번호, 이메일 주소<br>
          2. 수집 이용 목적: 접수 문의에 대한 답변 및 안내 <br>
          3. 보유 및 이용기간: 향후 분쟁처리 및 민원처리 위한 1년 보관 후 파기
        </p>
      </div>

      <div class="col-12" style="right:-15px;" align="right">
        <label id="disagree" v-on:click="disagreeing"><img src="@/assets/grayRadio.svg" id="icon_disagree">동의 하지
          않음</label>

        <label id="agree" v-on:click="agreeing"><img src="@/assets/grayRadio.svg" id="icon_agree">동의</label>
      </div>
    </div>

    <!-- <div class="row d-block d-md-none" id="agreementContainer_xs">
        <div class="col-12" id="agreementTitle">
            개인정보 수집 및 이용에 대한 안내 (필수)
        </div>

        <div class="col-12" id="agreementContent_xs">
            <p>
                고객님들의 의견 접수에 필요한 최소한의 개인정보를 수집하고 있으며, 본 문의 기능은 개인정보 이용 동의 후 이용하실 수 있습니다.</br></br>
                1. 필수 수집 항목: 이름, 이메일 주소</br>
                2. 수집 이용 목적: 접수 문의에 대한 답변 및 안내 </br>
                3. 보유 및 이용기간: 향후 분쟁처리 및 민원처리 위한 1년 보관 후 파기
            </p>
        </div>

        <div class="col-12" style="right:0px;" align="right">
                <label id="disagree_xs" v-on:click="disagreeing"><img src="@/assets/grayRadio.svg" id="icon_disagree_xs">동의 하지 않음</label>

                <label id="agree_xs" v-on:click="agreeing"><img src="@/assets/grayRadio.svg" id="icon_agree_xs">동의</label>
        </div>
    </div> -->

    <form>
      <div class="row justify-content-between" id="ContactRow">
        <div class="col-12 col-md-6" id="ContactCell">
          <div>
            <input ref="form_name" type="text" class="Cell ContactFormInput_required" required
                   id="ContactFormInput_required1"
            >
            <div id="Custom_placehodler">이름<span id="requiredMark"> *</span></div>
          </div>
        </div>

        <div class="w-100 d-block d-md-none" id="rowDivider"></div>

        <div class="col-12 col-md-6" id="ContactCell">
          <div style="display:block; margin: 0 0 auto; ">
            <input ref="form_phone" type="text" class="Cell ContactFormInput_required" required
                   style=""
                   id="ContactFormInput_required2">
            <div id="Custom_placehodler">휴대폰 번호<span id="requiredMark"> *</span></div>
          </div>
        </div>
        <div class="w-100 d-block d-md-none" id="rowDivider"></div>
      </div>

      <div class="row justify-content-between" id="ContactRow">
        <div class="col-12  col-md-6" id="ContactCell">
          <input ref="form_email" type="text" class="Cell ContactFormInput_required" required
                 id="ContactFormInput_required3">
          <div id="Custom_placehodler">이메일 주소<span id="requiredMark"> *</span></div>
        </div>
        <div class="w-100 d-block d-md-none" id="rowDivider"></div>
        <div class="col-12  col-md-6" id="ContactCell">
          <input ref="form_address" type="text" class="Cell ContactFormInput_required" required
                 id="ContactFormInput_required4">
          <div id="Custom_placehodler">주소<span id="placeholder_example"> (예: 반포대로 58, 삼성동 25)</span></div>
        </div>
        <div class="w-100 d-block d-md-none" id="rowDivider"></div>
      </div>

      <div class="row" id="ContactFormRow_memo">
        <div class="col-12" id="ContactCell">
          <!-- <textarea ref="form_memo2" type="text" class="d-block d-md-none Cell_memo_md ContactFormInput_required" required rows="5" -->
          <!-- id="ContactFormInput_required5" /> -->
          <!-- <div id="Custom_placehodler" class="d-block d-md-none"> -->
          <!-- 메모</br> -->
          <!-- <span id="placeholder_example"> 예) 회사 차량 20대 관제 및 엔진음 변경을 위한 견적을 받아보고 싶어요.</span> -->
          <!-- </div>                     -->


          <textarea ref="form_memo1" type="text" class="d-block d-md-block Cell_memo ContactFormInput_required" required
                    rows="5"
                    id="ContactFormInput_required5" style="width: 100%;"/>

          <div id="Custom_placehodler" class="d-block d-md-block ">
            메모<br>
            <span id="placeholder_example"></span>
          </div>
        </div>
      </div>

      <div class="row d-block d-md-block" style="padding: 0 16px 0 16px;">
        <div class="col" id="requirementError">
          <div class="alert alert-primary" role="alert">
            <span id="requiringFields"></span>
          </div>
        </div>
      </div>

      <!-- <div class="row d-block d-md-block" style="padding: 0 16px 0 16px;">
          <div class="col" id="requirementError">
              <div class="alert alert-primary" role="alert">
                  <span id="requiringFields_xs"></span>
              </div>
          </div>
      </div> -->

      <div class="row ">
        <div class="col-10 col-md-6">
          <div id="uploadedFile">
            <img src="@/assets/attachment.svg" id="uploadedFileImg">
            <span id="uploadedFileName">File.zip</span>
            <img src="@/assets/cancel_orange.svg" id="deleteFile" v-on:click="deleteFile()">
          </div>
        </div>
      </div>

      <div class="row" style="padding-left:0px; padding-right:0px; margin:0px; width:99%;">
        <input ref="uploadNameCard" id='fileid' type='file'
               name='filename' defaultValue="" v-on:click="uploadFile()" hidden v-on:change="fileUploaded()"/>
        <button ref="uploadNameCardBtn" type="button" id="ContactUploadFileBtn"
                class="btn btn-outline-info col-5 col-md-3 col-lg-2" v-on:click="uploadNameCardBtnClicked()"> 파일 첨부하기
        </button>
        <div class="col"></div>
        <button type="button" class="btn btn-primary ml-auto col-4 col-md-3 col-lg-2" id="ContactSendBtn"
                v-on:click="sendMail()">
          <div class="spinner-border" style="height:18px; width:18px; display: none;"
               role="status" id="sendSpinner">
            <span class="sr-only">Loading...</span>
          </div>
          <span id="sendText">보내기</span>
        </button>

      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
var ses = require('node-ses')
    , client = ses.createClient({
  key: 'AKIA3F7E7UC32ILWSUUN',
  secret: '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',
  amazon: 'https://email.ap-northeast-2.amazonaws.com'
});
export default {
  name: 'ContactTouchForm',
  components: {},
  data: () => {

    if (true)                // depending on card selection
      return {
        agreement: false
      }
    else
      return {
        Card_title: "가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다.",
        Card_subtitle: "Fleet Management Solution",
        Card_description: "LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합 관리하는 솔루션으로 발전해 나아갈 것 입니다.",
      }
  },
  mounted() {
    var agreement = false;
    // var textarea = document.getElementById("ContactFormInput_textarea");
    // textarea.placeholder = "메모\n예) 회사 차량 20대 관제 및 엔진음 변경을 위한 견적을 받아보고 싶어요.";
  }


  ,
  methods: {
    disagreeing() {
      this.agreement = false;

      var text = document.getElementById("agree");
      text.style.color = "#2d3436";

      var text = document.getElementById("disagree");
      text.style.color = "#e67e22";

      // var text = document.getElementById("agree_xs");
      // text.style.color = "#2d3436";

      // var text = document.getElementById("disagree_xs");
      // text.style.color = "#e67e22";

      var disIcon = document.getElementById("icon_disagree");
      disIcon.src = require("@/assets/orangeRadio.svg");

      var agreeIcon = document.getElementById("icon_agree");
      agreeIcon.src = require("@/assets/grayRadio.svg");

      // var disIcon = document.getElementById("icon_disagree_xs");
      // disIcon.src = require("@/assets/orangeRadio.svg");

      // var agreeIcon = document.getElementById("icon_agree_xs");
      // agreeIcon.src = require("@/assets/grayRadio.svg");

    }
    ,
    agreeing() {
      this.agreement = true;

      var text = document.getElementById("disagree");
      text.style.color = "#2d3436";
      var text = document.getElementById("agree");
      text.style.color = "#1d55e8";
      // var text = document.getElementById("disagree_xs");
      // text.style.color = "#2d3436";
      // var text = document.getElementById("agree_xs");
      // text.style.color = "#1d55e8";
      this.buttonActivate();

      var disIcon = document.getElementById("icon_disagree");
      disIcon.src = require("@/assets/grayRadio.svg");

      var agreeIcon = document.getElementById("icon_agree");
      agreeIcon.src = require("@/assets/blueRadio.svg");

      // var disIcon = document.getElementById("icon_disagree_xs");
      // disIcon.src = require("@/assets/grayRadio.svg");

      // var agreeIcon = document.getElementById("icon_agree_xs");
      // agreeIcon.src = require("@/assets/blueRadio.svg");


    },
    buttonActivate() {
      var btn = document.getElementById("ContactSendBtn_disabled")
      var box = document.getElementById("agreementContent_error");
      // var box_xs = document.getElementById("agreementContent_error_xs");
      if (btn) {
        btn.id = "ContactSendBtn";
        if (box)
          box.id = "agreementContent";
        // if(box_xs)
        // box_xs.id = "agreementContent_xs";
      }

    }
    ,
    buttonDisabled() {
      var btn = document.getElementById("ContactSendBtn")
      var box = document.getElementById("agreementContent");
      // var box_xs = document.getElementById("agreementContent_xs");
      if (btn) {
        btn.id = "ContactSendBtn_disabled";
        if (box)
          box.id = "agreementContent_error";
        // if(box_xs)
        // box_xs.id = "agreementContent_error_xs";
      }
    }
    ,

    deleteFile() {
      var uploadedTag = document.getElementById("uploadedFile");
      uploadedTag.style.display = "none";
    },
    uploadNameCardBtnClicked: function () {
      this.$refs.uploadNameCard.click();
    },
    uploadFile: function () {

    },
    fileUploaded() {
      var uploadedTag = document.getElementById("uploadedFile");
      uploadedTag.style.display = "flex";
      var uploadedName = document.getElementById("uploadedFileName");
      uploadedName.innerText = this.$refs.uploadNameCard.files[0].name;
      // uploadedTag.style.display = "none";
      console.log(this.$refs.uploadNameCard.files[0].name);       //file name
      console.log(this.$refs.form_phone.value);                   //form phone
      console.log(this.$refs.form_name.value);                   //form name
    },
    removeError() {
      console.log("ASDF")
    },
    // sendMail : function(event) {
    //     console.log(this.$refs.uploadNameCard.value);
    // }
    sendMail: function () {

      var name = this.$refs.form_name.value;
      var phone = this.$refs.form_phone.value;
      var email = this.$refs.form_email.value;
      var address = this.$refs.form_address.value;
      var memo1 = this.$refs.form_memo1.value;
      // var memo2 = this.$refs.form_memo2.value;
      var file = this.$refs.uploadNameCard.files[0];

      var required = 1;
      if (!name) required *= 2;
      if (!phone) required *= 3;
      if (!email) required *= 5;

      if (!this.agreement) {
        this.disagreeing();
        this.buttonDisabled();
      } else {
        this.agreeing();
      }
      if (required != 1) {
        var requiringField = "";
        console.log(required);
        document.getElementById("requirementError").style.display = "flex";
        // document.getElementById("requirementError_xs").style.display = "flex";
        if (required % 2 == 0) {
          if (document.getElementById("ContactFormInput_required1"))
            document.getElementById("ContactFormInput_required1").id = "ContactFormInput_required1_error"
          requiringField += "이름 ";
        } else {
          if (document.getElementById("ContactFormInput_required1_error"))
            document.getElementById("ContactFormInput_required1_error").id = "ContactFormInput_required1"
        }
        if (required % 3 == 0) {
          if (document.getElementById("ContactFormInput_required2"))
            document.getElementById("ContactFormInput_required2").id = "ContactFormInput_required2_error"
          requiringField += "휴대폰번호 ";
        } else {
          if (document.getElementById("ContactFormInput_required2_error"))
            document.getElementById("ContactFormInput_required2_error").id = "ContactFormInput_required2"
        }

        if (required % 5 == 0) {
          if (document.getElementById("ContactFormInput_required3"))
            document.getElementById("ContactFormInput_required3").id = "ContactFormInput_required3_error"
          requiringField += " 이메일 주소 ";
        } else {
          if (document.getElementById("ContactFormInput_required3_error"))
            document.getElementById("ContactFormInput_required3_error").id = "ContactFormInput_required3"
        }
        var placeholder_requiredField = "(필수) 영역을 채워주세요.";
        document.getElementById("requiringFields").innerHTML = requiringField + placeholder_requiredField;
        // document.getElementById("requiringFields_xs").innerHTML = requiringField + placeholder_requiredField;

      } else if (required == 1) {

        if (document.getElementById("ContactFormInput_required1_error"))
          document.getElementById("ContactFormInput_required1_error").id = "ContactFormInput_required1"
        if (document.getElementById("ContactFormInput_required2_error"))
          document.getElementById("ContactFormInput_required2_error").id = "ContactFormInput_required2"
        if (document.getElementById("ContactFormInput_required3_error"))
          document.getElementById("ContactFormInput_required3_error").id = "ContactFormInput_required3"
        document.getElementById("requirementError").style.display = "none";
        // document.getElementById("requirementError_xs").style.display = "none";

        var text = document.getElementById("sendText");
        var spinner = document.getElementById("sendSpinner");
        var sendBtn = document.getElementById("ContactSendBtn");
        sendBtn.classList.add("disabled");
        // spinner.style.display = "inline-block";
        text.innerHTML = "전송 시작됨"

        if (file) {
          var fileName = "test.png"
          var fileData = "";
          var reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = function () {
            var dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
            client.sendEmail({
                  to: 'genswell@genswell.com',
                  cc: "",
                  from: "genswell@genswell.com",
                  subject: "젠스웰 홈페이지에서 보내는 메일",
                  message: "이름: " + name +
                      " / 휴대폰번호 : " + phone +
                      " / 이메일 : " + email +
                      " / 주소 : " + address +
                      " / 메모 : " + memo1,
              Attachments: [
                {
                  name: file.name,
                  data: dataUri
                }]
                }, function (err, data, res) {
                  var text = document.getElementById("sendText");
                  var spinner = document.getElementById("sendSpinner");
                  var sendBtn = document.getElementById("ContactSendBtn");
                  console.log(err, data, res);
                  if (err === null) {
                    text.style.display = "inline-block";
                    text.innerHTML = "전송 완료!"
                    spinner.style.display = "none";
                    sendBtn.classList.remove("disabled");
                  } else {
                    alert("메일 전송에 문제가 발생했습니다. 새로고침 후 다시 시도해주세요.");
                  }
                }
            );
          };
          reader.onerror = function () {
            console.log('there are some problems');
          };
        } else {
          client.sendEmail({
                to: 'genswell@genswell.com',
                cc: "",
                from: "genswell@genswell.com",
                subject: "젠스웰 홈페이지에서 보내는 메일",
                message: "이름: " + name +
                    " / 휴대폰번호 : " + phone +
                    " / 이메일 : " + email +
                    " / 주소 : " + address +
                    " / 메모 : " + memo1,
              }, function (err, data, res) {
                var text = document.getElementById("sendText");
                var spinner = document.getElementById("sendSpinner");
                var sendBtn = document.getElementById("ContactSendBtn");
                console.log(err, data, res);
                if (err === null) {
                  text.style.display = "inline-block";
                  text.innerHTML = "전송 완료!"
                  spinner.style.display = "none";
                  sendBtn.classList.remove("disabled");
                } else {
                  alert("메일 전송에 문제가 발생했습니다. 새로고침 후 다시 시도해주세요.");
                }
              }
          );
        }
      }
    },
  },

}


</script>


<style scoped>
.container {
  padding: 0;
}

#requiredMark {
  position: relative;
  top: -3px;
  color: #1d55e8;
  font-weight: bold;
}

#ContactFormInput_required:invalid + #Custom_placehodler {
  overflow: hidden;
  position: absolute;
  padding-top: 32px;
  padding-left: 32px;
  color: #636e72;
}

#ContactFormInput_required:focus + #Custom_placehodler {
  transition-duration: 0.3s;
  transform: translateY(-30%);
  font-size: 12px;
}

#ContactFormInput_required:valid + #Custom_placehodler {
  transition-duration: 0.3s;
  transform: translateY(-30%);
  font-size: 12px;
}

.ContactFormInput_required:invalid + #Custom_placehodler {
  overflow: hidden;
  position: absolute;
  padding-top: 32px;
  padding-left: 32px;
  color: #636e72;
}

.ContactFormInput_required:focus + #Custom_placehodler {
  transition-duration: 0.3s;
  transform: translateY(-30%);
  font-size: 12px;
}

.ContactFormInput_required:valid + #Custom_placehodler {
  transition-duration: 0.3s;
  transform: translateY(-30%);
  font-size: 12px;
}


#ContactFormInput_required:focus + #Custom_placehodler #placeholder_example {
  display: none;
}

#ContactFormInput_required:valid + #Custom_placehodler #placeholder_example {
  display: none;
}

.ContactFormInput_required:focus + #Custom_placehodler #placeholder_example {
  display: none;
}

.ContactFormInput_required:valid + #Custom_placehodler #placeholder_example {
  display: none;
}

#requirementError_xs {
  display: none;
  position: relative;
  margin-top: 8px;
  /* left: 20px; */
  padding: 0px;
}

#requirementError_xs > .alert {
  margin: 0px;
  border: none;
  top: 0;
  bottom: 0;
  position: relative;
  padding-left: 8px;
  width: 88%;
  padding-left: 8px;
  padding-right: 8px;
  white-space: pre-wrap;
  text-align: left;
  font-size: 15px;
  background-color: #e67e22;
  color: #ffffff;
}


#requirementError {
  display: none;
  position: relative;
  margin-top: 8px;
  width: 99%;
  /* left: 20px; */
  padding: 0px;
}

#requirementError > .alert {
  margin: 0px;
  border: none;
  top: 0;
  bottom: 0;
  position: relative;
  padding-left: 32px;
  width: 100%;
  text-align: left;
  background-color: #e67e22;
  color: #ffffff;
}

#ContactFormInput_required1 {
  width: 99%;
}

#ContactFormInput_required2 {
  width: 99%;
}

#ContactFormInput_required3 {
  width: 99%;
}

#ContactFormInput_required4 {
  width: 99%;
}

#ContactFormInput_required1_error {
  width: 99%;
  border: solid 1px #e67e22;
}

#ContactFormInput_required1_error:focus {
  border: solid 1px #1d55e8;
}

#ContactFormInput_required1_error + #Custom_placehodler {
  color: #e67e22;
}

#ContactFormInput_required1_error + #Custom_placehodler > #requiredMark {
  color: #e67e22;
}

#ContactFormInput_required2_error {
  width: 99%;
  border: solid 1px #e67e22;
}

#ContactFormInput_required2_error + #Custom_placehodler {
  color: #e67e22;
}

#ContactFormInput_required2_error + #Custom_placehodler > #requiredMark {
  color: #e67e22;
}

#ContactFormInput_required3_error {
  width: 99%;
  border: solid 1px #e67e22;
}

#ContactFormInput_required3_error + #Custom_placehodler {
  color: #e67e22;
}

#ContactFormInput_required3_error + #Custom_placehodler > #requiredMark {
  color: #e67e22;
}


#uploadedFile {
  display: none;
  position: relative;
  left: 4px;
  text-align: left;
  border-radius: 4px;
  border: solid 2px #ecf0f1;
  padding: 8px;
  background-color: #ffffff;
  margin-top: 8px;
  margin-bottom: 8px;
}

#uploadedFileImg {
  object-fit: fill;
  height: 24px;
  margin-right: 12px;
}

#deleteFile {
  object-fit: fill;
  height: 24px;
  position: absolute;
  right: 0;
  margin-right: 8px;
}

.ContactMemo {
  box-sizing: border-box;
  min-height: 87px;
  background-color: #ecf0f1;
  border: #ecf0f1;
  padding-left: 32px;
  padding-top: 64px;
  padding-bottom: 16px;
  resize: none;
}


#ContactRow {
  position: relative;
  min-height: 93px;
  margin: 0px;
}

#ContactCell {
  position: relative;
  padding: 0px;
  height: 90px;

}

#Custom_placehodler {

  pointer-events: none;
  text-align: left;
  overflow: hidden;
  position: relative;
  padding-top: 32px;
  padding-left: 32px;
  color: #636e72;
  z-index: 2;
}

.Cell {
  position: relative;
  left: 0px;
}

.Cell_memo {
  position: relative;
  resize: none;
}

.Cell_memo_md {
  position: relative;
  /* left: 20px; */
  /* width: 96%; */
}

#ContactFormRow_memo {
  min-height: 186px;
  /* margin-bottom: 24px; */
  padding-left: 16px;
  padding-right: 20px;
  word-break: keep-all;
}


#icon_disagree {
  margin-right: 8px;
}

#icon_agree {
  margin-right: 8px;
}

#icon_disagree_xs {
  margin-right: 8px;
}

#icon_agree_xs {
  margin-right: 8px;
}

#ContactFormInput {
  /* height: 87px; */
  /* width: 460px; */
  min-height: 87px;
  background-color: #ecf0f1;
  border: #ecf0f1;
  padding-left: 32px;

  padding-top: 16px;
  padding-bottom: 16px;
}

#ContactFormInput_required {
  /* box-sizing: border-box; */
  min-height: 87px;
  background-color: #ecf0f1;
  /* border: #ecf0f1; */
  padding-left: 32px;
  padding-top: 16px;

  position: absolute;
  padding-bottom: 16px;
}

.ContactFormInput_required {
  max-width: 100%;
  /* box-sizing: border-box; */
  min-height: 87px;
  background-color: #ecf0f1;
  border: #ecf0f1;
  width: 100%;
  height: auto;
  padding-left: 32px;
  /* padding-right: 132px; */
  padding-top: 16px;
  position: absolute;
  padding-bottom: 16px;
}

#ContactFormInput_required_error {
  /* box-sizing: border-box; */
  min-height: 87px;
  background-color: #ecf0f1;
  border: #e67e22;
  border: solid 2px #e67e22;
  padding-left: 32px;
  padding-top: 16px;
  position: absolute;
  padding-bottom: 16px;
}

.column-marginer {
  margin-bottom: 87px;
}

textarea#ContactFormInput_required {
  padding-top: 36px;
  overflow: hidden;
}

textarea.ContactFormInput_required {
  padding-top: 36px;
  overflow: hidden;
}


#ContactFormColumn_left {
  padding-left: 0px;
  padding-right: 15px;
}

#ContactFormColumn_right {
  padding-left: 15px;
  padding-right: 0px;
}

#ContactTouchContainer {
  margin-top: 186px;
  min-height: 100px;

  position: relative;
}

#ContactFormContainer {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 64px;
}

#ContactSendBtn {
  margin-top: 8px;
  border-radius: 4px;
  background-color: #1d55e8;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'NanumSquareB';
  height: 36px;
}

#ContactSendBtn_disabled {
  margin-top: 8px;
  border-radius: 4px;
  background-color: rgba(177, 190, 195, 0.7);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #636e72;
  font-family: 'NanumSquareB';
  height: 36px;
}

#ContactUploadFileBtn {
  margin-top: 8px;
  /* left: 20px; */
  min-height: 36px;
  border-radius: 4px;
  border: solid 1px #1d55e8;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1d55e8;
  font-family: 'NanumSquareB';
}

#ContactUploadFileBtn:hover {
  border-color: #1d55e8;
  color: #ffffff;
  background-color: #1d55e888;
  text-decoration: none;
}

#agreementContainer {


  position: relative;

  width: 99%;
  text-align: left;
  margin: 0 0 24px 0;
  padding: 0px;
}

#agreementContainer_xs {

  margin-bottom: 16px;
  position: relative;

  width: 100%;
  text-align: left;
}

#agreementTitle {
  padding-left: 0;
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#ContactInputCell {

  padding: 0px;
}

#rowDivider {
  /* height: 96px; */
  width: auto;
}

#agreeRadio {
  margin-left: 50px;

}

p {
  margin-bottom: 0px;
}

#agree {
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: 1000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 36px;
}

#disagree {
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: 1000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 36px;
}

#agree_xs {
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: 1000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 16px;
}

#disagree_xs {
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: 1000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 36px;
}

#agreementContent {
  word-break: keep-all;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 36px 16px 36px 32px;
  background: #ecf0f1;

  border: solid 2px transparent;
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
}

#agreementContent_error {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 36px 16px 36px 32px;
  background: #ecf0f1;


  border: solid 2px #e67e22;
  color: #e67e22;
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;

}


#agreementContent_xs {
  width: 98%;
  margin-top: 8px;
  margin-bottom: 8px;

  padding: 8px;
  background: #ecf0f1;

  border: solid 2px transparent;
  font-family: 'NanumSquare';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
}

#agreementContent_error_xs {
  width: 98%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background: #ecf0f1;


  border: solid 2px #e67e22;
  color: #e67e22;
  font-family: 'NanumSquare';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;

}


</style>
