<template>
  <div class="Gachita">
        <FeatureMain2
          Card_subtitle="Fleet Management Solution"
          Card_title="가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다."
          Card_description="LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합관리하는 솔루션으로 발전해 나아갈 것 입니다."
          ImageSrc="gachita_main.png"
          />

        <FeatureSubs
            Card_subtitle="LPWA 기반 차량 종합 관제 솔루션"
            Card_title="FMS"
            Card_description="복합 차량 관제, 원격 고장진단, 운행기록부 작성 (국세청 자료 양식 대체) 등 다수의 차량을 효율적으로 관리할 수 있는 솔루션입니다."
            ImageSrc1="gachita_1_1.png"
            ImageSrc2="gachita_1_2.png"/>
        <FeatureSub
            Card_subtitle="LAST MILE 물류관리 서비스"
            Card_title="WMS"
            Card_description="물류의 출발과 도착, 라인 현장에서의 인벤토리 관리 등, 자재 관리의 효율성을 극대화 할 수 있는 Last mile 물류관리 솔루션 입니다."
            ImageSrc="gachita_2.png"
            />
        <FeatureSubs
            Card_subtitle="장애인용 카헤일링 서비스"
            Card_title="CAR HAILING"
            Card_description="복합 차량 관제 솔루션과 실시간 배차 로직을 활용하여 교통약자를 위한 카 헤일링 서비스인 '이유'에 운영 관리 솔루션을 제공중에 있습니다."
            ImageSrc1="gachita_3_1.png"
            ImageSrc2="gachita_3_2.png"/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import FeatureMain2 from '@/components/features/FeatureMain2.vue'
import FeatureSubs from '@/components/features/FeatureSubs.vue'
import FeatureSub from '@/components/features/FeatureSub.vue'

export default {
  name: 'Gachita',
  components: {
    FeatureMain2,
    FeatureSubs,
    FeatureSub

  }
}
</script>
