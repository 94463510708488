<template>
  <div class="contact">
    <ContactTouch/>
    <ContactMap/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ContactTouch from '@/components/contact/ContactTouch.vue'
import ContactMap from '@/components/contact/ContactMap.vue'

export default {
  name: 'Contact',
  components: {
    ContactMap,
    ContactTouch,
  }
}
</script>

<style scoped>
/* .container{
  padding: 0px;
} */
</style>
