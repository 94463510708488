<template>
  <div class="Genswell">
        <FeatureMain
          Card_subtitle="Sound Control Solution"
          Card_title="젠스웰은 운전의 즐거움을 주기위한 다양한 사운드 솔루션 입니다."
          Card_description="엔진음을 풍부하게 해주고, 시트에 장착하여 풍부한 우퍼효과와 진동을 전달하여 운전에 재미를 주고, 다이나믹한 배기음으로 고성능 차량의 아이덴티티를 살리는 기술과 제품으로 새로운 시장을 개척하고자 합니다."
          ImageSrc="genswell_main.png"
          />

        <FeatureSubs
            Card_subtitle="Electronic Sound Generator"
            Card_title="ESG"
            Card_description="전자식 사운드 제네레이터는 엔진 회전수와 토크, 속도 등 차의 주행과 관련한 상태에 알맞게 실내에서 운전자의 감성을 돋울 수 있는 소리를 만들어내는 장치입니다."
            ImageSrc1="genswell_1_1.png"
            ImageSrc2="genswell_1_2.png"/>
        <FeatureSubs
            Card_subtitle="Vibrating Woofer Seat"
            Card_title="VWS"
            Card_description="진동우퍼시트는 차량 실내에서 청각에 의한 전달방법에 국한되지 않고, 시트 진동을 통한 미디어 우퍼효과 및 경고진동을  승객에게 전달하는 장치입니다."
            ImageSrc1="genswell_2_1.png"
            ImageSrc2="genswell_2_2.png"/>
        <FeatureSubs
            Card_subtitle="Electronic Control Exhaust Value"
            Card_title="ECEV"
            Card_description="능동가변밸브는 차량 주행구간별 맵에 따른 밸브 개폐를 통하여 차량의 가속성능과 스포티한 사운드를 조화롭게 제어하는 장치입니다."
            ImageSrc1="genswell_3_1.png"
            ImageSrc2="genswell_3_2.png"/>
        <FeatureSubs
            Card_subtitle=""
            Card_title="Popup Speaker"
            Card_description="팝업 스피커는 미디어 재생시 부드럽고 자연스럽게 올라오는 퍼포먼스 효과를 부가하는 차별화된 전동 스피커 장치 입니다."
            ImageSrc1="genswell_4_1.png"
            ImageSrc2="genswell_4_2.png"/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import FeatureMain from '@/components/features/FeatureMain.vue'
import FeatureSubs from '@/components/features/FeatureSubs.vue'

export default {
  name: 'Genswell',
  components: {
    FeatureMain,
    FeatureSubs
  },
  
}
</script>
