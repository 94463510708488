<template>
    <div class="container" id="ContactMapContainer" style="min-width:320px;">
        <div class="row" style="width:100%; padding:16px; margin:0px;">
                <div class="col" id="Line"></div>

                <div class="col-3" id="MapComsTitle">오시는 길</div>

                <div class="col" id="Line"></div>
        </div>

        <div class="row justify-content-center"  style="padding:16px; margin:0px;" >

            <div class=" col-12 mapContainer" id="map">
            <!-- <div class="col-11 col-sm-12 mapContainer" id="map"> -->
            </div>
        </div>

        <div class="row" style="width:100%; padding:16px; margin:0px;">
            <div class="col-md-6">
                <img id="icon" src="@/assets/i_map.svg"><p id="textAlign">경기도 성남시 수정구 성남대로 1342 가천대학교 창업보육센터 B110호</p>
            </div>
            <div class="col-md-6 d-none d-md-block">
                <a href="http://naver.me/xZmaO5DU" target="_blank">
                <button class="pull-right btn btn-primary" id="naverMap" type="button">네이버 지도로 확인하기</button>
                </a>
            </div>

            <div class="w-100">
            </div>
            <div class="col-12">
                <img id="icon" src="@/assets/i_call.svg"><p id="textAlign">031-778-7949</p>
            </div>
            <div class="w-100">
            </div>
            <div class="col-12">
                <img id="icon" src="@/assets/i_mail.svg"><p id="textAlign">wc.jung@gachita.com</p>
            </div>

            <div class="col-md-6 d-block d-md-none" style="margin-bottom:16px; right:16px; padding:0;">
                <a href="http://naver.me/IIdbfogh" target="_blank">
                <button class="pull-right btn btn-primary" id="naverMap" type="button">네이버 지도로 확인하기</button>
                </a>
            </div>
            <!-- <div style="position:absolute; left:0; top:0; border-radius: 100px; border: solid 1px gray; width:350px; padding:4px; font-weight:bolder;">경기도 파주시 미래로 610(와동동 1303-1)</div> -->

        </div>

    </div>

</template>

<script>
// @ is an alias to /src
import JQuery from 'jquery'
let $ = JQuery


export default {
  name: 'ContactMap',
  components: {

  },
//   mounted()
  mounted(){
    var position = new naver.maps.LatLng(37.4510218,127.1269882);

    var CustomOverlay = function(options) {
    this._element = $('<div class="d-none d-md-block" style="position:absolute;left:0;top:0;">' +
                        '</div>'
                        +'<div class="d-md-none d-block" style="position:absolute;left:0;top:0;">' +
                        '</div>')


                        // +'<div class="d-md-none d-block" style="position:absolute;left:0;top:0;">' +
                        // '<img style="position:absolute;left:-116.615px;top:-32px;" src="'+require('@/assets/marker.png')+'" height="64px" >' +
                        // '</div>')

    // this._element = $('<div style="position:absolute;left:0;top:0;">' +
    //                     '<img style="position:absolute;left:-174.93px;top:-48px;" src="'+require('@/assets/marker.png')+'" height="96px" >' +
    //                     '</div>');

    this.setPosition(options.position);
    this.setMap(options.map || null);
};

CustomOverlay.prototype = new naver.maps.OverlayView();
CustomOverlay.prototype.constructor = CustomOverlay;

CustomOverlay.prototype.setPosition = function(position) {
    this._position = position;
    this.draw();
};

CustomOverlay.prototype.getPosition = function() {
    return this._position;
};

CustomOverlay.prototype.onAdd = function() {
    var overlayLayer = this.getPanes().overlayLayer;

    this._element.appendTo(overlayLayer);
};

CustomOverlay.prototype.draw = function() {
    if (!this.getMap()) {
        return;
    }

    var projection = this.getProjection(),
        position = this.getPosition(),
        pixelPosition = projection.fromCoordToOffset(position);

    this._element.css('left', pixelPosition.x);
    this._element.css('top', pixelPosition.y);
};

CustomOverlay.prototype.onRemove = function() {
    var overlayLayer = this.getPanes().overlayLayer;

    this._element.remove();
    this._element.off();
};

var position = new naver.maps.LatLng(37.4510218,127.1269882);
var map = new naver.maps.Map("map",
{
        center: new naver.maps.LatLng(37.4510218,127.1269882),
        zoomOrigin: new naver.maps.LatLng(37.4510218,127.1269882),
        zoom: 15,
        mapDataControl: false,
        scaleControl: false,
        logoControl: false,
    }
);
var overlay = new CustomOverlay({
    map: map,
    position: position
});


  },
  data : ()=>
  {
    if(true)                // depending on card selection
        return {
            Card_title: "젠스웰은 운전의 즐거움을 주기위한 다양한 사운드 솔루션 입니다.",
            Card_subtitle: "Sound Control Solution",
            Card_description: "엔진음을 풍부하게 해주고, 시트에 장착하여 풍부한 우퍼효과와 진동을 전달하여 운전에 재미를 주고, 다이나믹한 배기음으로 고성능 차량의 아이덴티티를 살리는 기술과 제품으로 새로운 시장을 개척하고자 합니다.",
        }
    else
        return {
            Card_title: "가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다.",
            Card_subtitle: "Fleet Management Solution",
            Card_description: "LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합 관리하는 솔루션으로 발전해 나아갈 것 입니다.",
        }
  }
}


</script>


<style scoped>
.mapContainer{
    height: 50vh;
    width: 100%;
}
.row{
    padding: 0px;
}
#textAlign{
    padding-left: 8px;
    text-align: left;
}
#Map{
    height: 100%;
    width: 10%;
}
#MapDataContainer{
    border: 1px solid gray;
    border-radius: 25px;
    padding: 16px;
}

@media only screen and (max-width: 600px) {
#ContactMapContainer{
    padding: 0px;
    margin-top: 64px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}
}

@media only screen and (min-width: 600px) {
#ContactMapContainer{
    padding-left: 0px;
    margin-top: 86px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}
}
/* #ContactMapContainer{
    padding-left: 36px;
    margin-top: 86px;
    min-height: 300px;
    height: 100%;
    width: 100%;
} */
#Line{
  height: 1px;
  border: solid 0.5px #636e72;
  position: relative;
  top: 8px;                 /* should it be chagned? */
}
#MapComsTitle{
    white-space: nowrap;
    padding: 0px;
}
#naverMap{
    position: absolute;
    right: 0;
    background-color: #1d55e8;
}
#icon{
    margin-top: 4px;
    height: 16px;
    width: 16px;
    object-fit: contain;
    position: absolute;
    left: 0;
}
</style>
