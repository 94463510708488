<template>
    <div class="container" id="FeatureMainContainer">
        <div class="row">
            
            <div class="col" id="FeatureSubsSubtitle">{{ Card_subtitle }}</div>
        </div>
        <div class="row">
            
            <div class="col" id="FeatureSubsTitle">{{ Card_title }}</div>
        </div>
        <div class="row">
            
            <div class="col-12 col-md-9 col-lg-5 col-xl-5" id="FeatureSubsDescription">{{ Card_description }}</div>
        </div>
        <div class="row">
            
            <div class="col-6">
                <img :src="require('@/assets/'+ImageSrc1)" class="rounded" id="FeatureSubsDescriptionImg"/>
            </div>
            <div class="col-6">
                <img :src="require('@/assets/'+ImageSrc2)" class="rounded" id="FeatureSubsDescriptionImg"/>
            </div>

        </div>
            
        
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FeatureSubs',
  components: {
      
  },
  props:['Card_subtitle', 'Card_title', 'Card_description', 'ImageSrc1','ImageSrc2'],
  data : ()=>
  {
      return {}
    // if(true)                // depending on card selection
    //     return {
    //         Card_title: "젠스웰은 운전의 즐거움을 주기위한 다양한 사운드 솔루션 입니다.",
    //         Card_subtitle: "Sound Control Solution",
    //         Card_description: "엔진음을 풍부하게 해주고, 시트에 장착하여 풍부한 우퍼효과와 진동을 전달하여 운전에 재미를 주고, 다이나믹한 배기음으로 고성능 차량의 아이덴티티를 살리는 기술과 제품으로 새로운 시장을 개척하고자 합니다.",
    //     }
    // else
    //     return {
    //         Card_title: "가치타는 효과적으로 차량을 관리할 수 있는 관제 솔루션입니다.",
    //         Card_subtitle: "Fleet Management Solution",
    //         Card_description: "LPWA기반의 관제 단말과 커스터마이징이 가능한 관제 플랫폼을 통해 차량을 하나처럼 통합 관리해 줄 수 있습니다. 향후, 차량과 물류를 하나로 묶어 통합 관리하는 솔루션으로 발전해 나아갈 것 입니다.",
    //     }
  }
}
</script>


<style scoped>
#FeatureSubsSubtitle{
  text-align: left;
  font-family: 'NanumSquareB';
  font-size: 13px;
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151819;
  position: relative;
  margin-bottom: 16px;
}

#FeatureSubsTitle::after{
    content:"\A";
    width:8px;
    height:8px;
    border-radius:50%;
    margin-left: 3px;
    display:inline-block;
    background-color: #1d55e8;
}
#FeatureSubsTitle{
    position: relative;
    text-align: left;
  font-family: 'NanumSquareB';
  font-size: 36px;
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151819;
  margin-bottom: 16px;
}
#FeatureSubsDescription{
    
  word-break: keep-all;
  
  text-align: left;
  font-family: 'NanumSquare';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
  margin-bottom: 16px;
  }

#FeatureSubsDescriptionImg{
    /* padding-bottom: 60px; */
    height: 100%;
    width: 100%;
    object-fit: contain;
}

#FeatureMainContainer{
    margin-top: 64px;
    min-width: 320px;
}


</style>